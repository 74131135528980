import React from 'react';
import './style.css';
import { useSelector } from 'react-redux';
import AppsPage from '../appsPage';
import LogPage from '../logPage';

const MainPage = () => {
	const page = useSelector((state) => state.page);

	return (
		<div className="main_page__wrapper">
			<div className="main_page">
				{page.data === 'Приложения' ? <AppsPage /> : <LogPage />}
			</div>
		</div>
	);
};

export default MainPage;
