import axios from 'axios';
import { URL_SERVER } from '../utils/constants';
import { setSession } from '../redux/actions/session';
import store from '../redux/store';
import { setDataProfile } from '../redux/actions/profile';
import { setDataAlert } from '../redux/actions/alert';

export const getProfile = async () => {
	await axios
		.get(`${URL_SERVER}/api/v1/users`, { withCredentials: true })
		.then((res) => {
			store.dispatch(setDataProfile(res.data[0]));
		})
		.catch((err) => {
			if (err.message.includes('401')) {
				console.log(err.message);
				store.dispatch(setSession(false));
			} else if (
				err.message.includes('403') &&
				err.response.data.message === 'Blocked'
			) {
			}
		});
};

export const changeName = async (props) => {
	const { name } = props;
	const profile = store.getState().profile;
	await axios
		.patch(
			`${URL_SERVER}/api/v1/users/${profile.data.id}`,
			{ name: name },
			{ withCredentials: true }
		)
		.then((res) => {
			store.dispatch(setDataProfile(res.data));
			store.dispatch(
				setDataAlert({
					show: true,
					message: 'Имя изменено',
				})
			);
		})
		.catch((err) => {
			if (err.message.includes('401')) {
				console.log(err.message);
				store.dispatch(setSession(false));
			}
		});
};
