import { createSlice } from '@reduxjs/toolkit';

export const sessionSlice = createSlice({
	name: 'session',
	initialState: {
		authorized: true,
	},
	reducers: {
		setSession: (state, data) => {
			state.authorized = data.payload ? true : false;
		},
	},
});

export const { setSession } = sessionSlice.actions;

export default sessionSlice.reducer;
