import React from 'react';
import './style.css';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { styled, alpha, Box } from '@mui/system';
import { Slider as BaseSlider, sliderClasses  } from '@mui/base/Slider'
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import CustomCheckbox from '../../checkboxes/customCheckbox';
import CustomInput from '../../inputs/customInput';
import CencelButton from '../../buttons/cencelButton';
import CustomButton from '../../buttons/customButton';
import CustomPassword from '../../inputs/customPassword';
import { updateApplication, createApplication } from '../../../api/apps';
import { codeTypes, MAX_CODE_LENGTH } from '../../../utils/constants';
import { setDataAlert } from '../../../redux/actions/alert';

const EditAppModal = (props) => {
	const { open, setClose, app, user } = props;
	const dispatch = useDispatch();
	const getInitType = () => {
		if (app && app.code_type === codeTypes.number && user.balance) return app.code_type;
		else if (app && app.code_type === codeTypes.sms && user.balanceSms) return app.code_type;
		return 'null';
	}
	const [selectOTP, setSelectOTP] = React.useState(
		app ? (app.auth_type === 0 || app.auth_type === 1) : true
	);
	const [selectOAUTH2, setSelectOAUTH2] = React.useState(
		app ? (app.auth_type === 0 || app.auth_type === 2) : false
	);
	const [type, setType] = React.useState(getInitType);
	const [appName, setAppName] = React.useState(app ? app.name : '');
	const [secretKey, setSecretKey] = React.useState(app ? app.client_secret : uuidv4().replace(/-/g, ''));
	const [authCallback, setAuthCallback] = React.useState(app ? app.redirect_uri : '');
	const [validName, setValidName] = React.useState(
		app?.name ? 'success' : 'root'
	);
	const [validSecretKey, setValidSecretKey] = React.useState('success');
	const [validCallback, setValidCallback] = React.useState(
		app?.redirect_uri ? 'success' : 'root'
	);
	const [errorNameMessage, setErrorNameMessage] = React.useState('');
	const [errorKeyMessage, setErrorKeyMessage] = React.useState('');
	const [errorCallbackMessage, setErrorCallbackMessage] = React.useState('');
	const [codeLength, setCodeLength] = React.useState(app ? app.code_length : 4);
	const mainColor = '#4D69FF';

	const closeModal = () => {
		setClose(false);
	};

	const handleSelectOTP = (value) => {
		if (!value && !selectOAUTH2) return;
		else setSelectOTP(value);
	};

	const handleSelectOAUTH2 = (value) => {
		if (!value && !selectOTP) return;
		else setSelectOAUTH2(value);
	};

	const handleSelectTypeSip = (value) => {
		if (!value) return;
		else if (user.balance) setType(codeTypes.number);
		else dispatch(
				setDataAlert({
					show: true,
					message: 'Пополните баланс звонков',
				})
			);
	};

	const handleSelectTypeSms = (value) => {
		if (!value) return;
		else if (user.balanceSms) setType(codeTypes.sms);
		else dispatch(
				setDataAlert({
					show: true,
					message: 'Пополните баланс смс',
				})
			);
	};

	const changeAppName = (value) => {
		setAppName(value);
		if (value.length) setValidName('success');
		else setValidName('error');
	};

	const changeSecretKey = (value) => {
		setSecretKey(value);
		if (value.length) setValidSecretKey('success');
		else setValidSecretKey('error');
	};

	const changeCallback = (value) => {
		setAuthCallback(value);
		if (value.length) setValidCallback('success');
		else setValidCallback('error');
	};

	const editApplication = async () => {
		if (
			(selectOAUTH2 &&
				validName === 'success' &&
				validSecretKey === 'success' &&
				validCallback === 'success' && type !== 'null') ||
			(!selectOAUTH2 &&
				selectOTP &&
				validName === 'success' &&
				validSecretKey === 'success' && type !== 'null')
		) {
			const body = {
				client_secret: secretKey,
				redirect_uri: authCallback,
				name: appName,
				auth_type: selectOTP && selectOAUTH2 ? 0 : selectOTP ? 1 : 2,
				code_type: type,
				code_length: codeLength
			};
			if (app) await updateApplication({ body, app });
			else await createApplication({ body });
			closeModal();
		} else if (validName !== 'success') {
			setValidName('error');
			setErrorNameMessage('Название приложения не введено');
		} else if (validSecretKey !== 'success') {
			setValidSecretKey('error');
			setErrorKeyMessage('Секретный ключ не введен');
		} else if (selectOAUTH2 && validCallback !== 'success') {
			setValidCallback('error');
			setErrorCallbackMessage('Адрес авторизации не введен');
		} else if (type === 'null') {
			dispatch(
				setDataAlert({
					show: true,
					message: 'Способ доставки кода не выбран',
				})
			);
		}
	};

	function SliderValueLabel({ children }) {
		return <span className="valueLabel">{children}</span>;
	}

	function valuetext(value) {
		return `${value}`;
	}

	const Slider = styled(BaseSlider)(
		({ theme }) => `
  color: ${mainColor};
  height: 6px;
  width: 100%;
  padding: 16px 0;
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;

  &.${sliderClasses.disabled} {
    pointer-events: none;
    cursor: default;
    color: ${mainColor};
    opacity: 0.4;
  }

  & .${sliderClasses.rail} {
    display: block;
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 6px;
    background-color: currentColor;
    opacity: 0.3;
  }

  & .${sliderClasses.track} {
    display: block;
    position: absolute;
    height: 4px;
    border-radius: 6px;
    background-color: currentColor;
  }

  & .${sliderClasses.thumb} {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-left: -6px;
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    border-radius: 50%;
    outline: 0;
    background-color: ${mainColor};
    transition-property: box-shadow, transform;
    transition-timing-function: ease;
    transition-duration: 120ms;
    transform-origin: center;

    &:hover {
      box-shadow: 0 0 0 6px ${alpha(
			mainColor,
			0.3,
		)};
    }

    &.${sliderClasses.focusVisible} {
      box-shadow: 0 0 0 8px ${alpha(
			mainColor,
			0.5,
		)};
      outline: none;
    }

    &.${sliderClasses.active} {
      box-shadow: 0 0 0 8px ${alpha(
			mainColor,
			0.5,
		)};
      outline: none;
      transform: scale(1.2);
    }
  }

  & .${sliderClasses.mark} {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 99%;
    background-color: ${mainColor};
    transform: translateX(-50%);
  }

  & .${sliderClasses.markActive} {
    background-color: ${mainColor};
  }

  & .valueLabel {
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 14px;
    position: relative;
    top: 2em;
    text-align: center;
    align-self: center;
  }
`,
	);

	return (
		<Modal
			open={open}
			onClose={closeModal}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<div className="add_app_modal">
				<h2>{app ? 'Редактировать' : 'Создать'} приложение</h2>
				<div className="add_app_modal__selectors">
					<CustomCheckbox
						label="OTP"
						checked={selectOTP}
						changeSelect={handleSelectOTP}
					/>
					<CustomCheckbox
						label="OAUTH 2"
						checked={selectOAUTH2}
						changeSelect={handleSelectOAUTH2}
					/>
				</div>
				<div className="add_app_modal__row">
					<span className="add_app_modal__row__label">
						Название приложения <span style={{ color: 'red' }}>*</span>
					</span>
					<CustomInput
						mode={validName}
						value={appName}
						changeInput={changeAppName}
						errorMessage={errorNameMessage}
					/>
				</div>
				<div className="add_app_modal__row">
					<span className="add_app_modal__row__label">
						Секретный ключ <span style={{ color: 'red' }}>*</span>
					</span>
					<CustomPassword
						mode={validSecretKey}
						value={secretKey}
						changeInput={changeSecretKey}
						errorMessage={errorKeyMessage}
					/>
				</div>
				<div
					className={
						selectOAUTH2
							? 'add_app_modal__row'
							: 'add_app_modal__row hidden_block'
					}
				>
					<span className="add_app_modal__row__label">
						Адрес авторизации в приложении (callback){' '}
						<span style={{ color: 'red' }}>*</span>
					</span>
					<CustomInput
						mode={validCallback}
						value={authCallback}
						changeInput={changeCallback}
						placeholder="https://example.com/auth"
						errorMessage={errorCallbackMessage}
					/>
				</div>
				<div className="add_app_modal__row">
					<span className="add_app_modal__row__label">Способ доставки кода</span>
					<div className="add_app_modal__transports">
						<CustomCheckbox
							label="ЗВОНОК"
							checked={type === codeTypes.number}
							changeSelect={handleSelectTypeSip}
						/>
						<CustomCheckbox
							label="SMS"
							checked={type === codeTypes.sms}
							changeSelect={handleSelectTypeSms}
						/>
					</div>
				</div>
				<div className="add_app_modal__row">
					<span className="add_app_modal__row__label">Количество символов кодовой комбинации</span>
					<Box sx={{width: 200}}>
						<Slider
							orientation="horizontal"
							value={codeLength}
							max={MAX_CODE_LENGTH}
							min={4}
							marks
							step={1}
							slots={{ valueLabel: SliderValueLabel }}
							getAriaValueText={valuetext}
							onChange={(value) => {
								setCodeLength(value.target.value);
							}}
						/>
					</Box>
				</div>
				<div className="add_app_modal__row__buttons">
					<div className="edit_app_modal__row__buttons_right">
						<CencelButton text={'Отмена'} onClick={closeModal} />
						<CustomButton text="Сохранить" onClick={editApplication}/>
					</div>
				</div>
				<CloseIcon className="close_button" onClick={closeModal} />
			</div>
		</Modal>
	);
};

export default EditAppModal;
