import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import FormControlLabel from '@mui/material/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	root: {
		'& .MuiFormControlLabel-root': {
			border: '1px solid #BEC3E6',
			borderRadius: 4,
			padding: '12px 16px',
			marginLeft: 0,
			userSelect: 'none',
		},
		'& .MuiFormControlLabel-label': {
			fontFamily: 'Inter',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: '15px',
			lineHeight: '22px',
			color: '#2C2D2E',
			padding: 0,
		},
		'& .MuiCheckbox-root': {
			padding: '0 11px 0 0',
		},
		'& .MuiSvgIcon-fontSizeSmall': {
			fontSize: '1rem',
			fill: '#BEC3E6',
		},
	},
	checked: {
		'& .MuiFormControlLabel-root': {
			border: '1px solid #4D69FF',
			borderRadius: 4,
			padding: '12px 16px',
			marginLeft: 0,
			userSelect: 'none',
		},
		'& .MuiFormControlLabel-label': {
			fontFamily: 'Inter',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: '15px',
			lineHeight: '22px',
			color: '#495BBF',
		},
		'& .MuiCheckbox-root': {
			padding: '0 11px 0 0',
		},
		'& .MuiSvgIcon-fontSizeSmall': {
			fontSize: '1rem',
			fill: '#4D69FF',
		},
	},
});

const CustomCheckbox = (props) => {
	const classes = useStyles();
	const { label, checked, changeSelect } = props;
	const changeCheckbox = (e) => {
		changeSelect(e.target.checked);
	};
	return (
		<div className={checked ? classes.checked : classes.root}>
			<FormControlLabel
				label={label}
				checked={checked}
				onChange={changeCheckbox}
				control={
					<Checkbox
						icon={<RadioButtonUncheckedRoundedIcon />}
						checkedIcon={<CheckCircleRoundedIcon />}
						size={'small'}
					/>
				}
			/>
		</div>
	);
};

export default CustomCheckbox;
