import React from 'react';
import './style.css';
import NavLogMenu from '../navLogMenu';
import LogTable from '../tables/logTable';
import LicenseTable from '../tables/licenseTable';
import { useSelector, useDispatch } from 'react-redux';
import { setDataPageTable } from '../../redux/actions/page';

const LogPage = () => {
	const dispatch = useDispatch();
	const statePage = useSelector((state) => state.page);

	const setPageTable = (value) => {
		dispatch(setDataPageTable(value));
	};
	return (
		<div className="log_page">
			<h2>Журнал</h2>
			<NavLogMenu page={statePage.table} changePage={setPageTable} />
			{statePage.table === 'Журнал' ? <LogTable /> : <LicenseTable />}
		</div>
	);
};

export default LogPage;
