import React from 'react';
import './style.css';
import CustomInput from '../../inputs/customInput';
import { activateLicense } from '../../../api/license';
import { codeTypes, MARKET_LINK_BUY } from '../../../utils/constants';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import CencelHeaderButton from '../../buttons/cencelHeaderButton';
import CustomButtonHeader from '../../buttons/customButtonHeader';
import { useDispatch } from 'react-redux';
import { setBitrixModal } from '../../../redux/actions/modals';

const BalanceModal = (props) => {
	const { opened, open, setClose, type } = props;
	const profile = useSelector((state) => state.profile);
	const [licenseValue, setLicenseValue] = React.useState('');
	const [licenseValid, setLicenseValid] = React.useState('root');
	const [count, setCount] = React.useState(0);
	const [isLoading, setIsLoading] = React.useState(false);
	const [forceLicenseInputShow, setForceLicenseInputShow] =
		React.useState(true);
	const [licenseInputShow, setLicenseInputShow] = React.useState(false);
	const [errorMessage, setErrorMesasge] = React.useState('');
	const [disabledActivateButton, setDisabledActivateButton] = React.useState(true);
	const dispatch = useDispatch();

	const closeModal = () => {
		setClose(false);
	};

	const checkValidate = (value) => {
		return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
			value
		);
	};

	const changeInput = (value) => {
		setLicenseValue(value);
		setDisabledActivateButton(true);
		if (value.length && checkValidate(value)) {
			setLicenseValid('success');
			setErrorMesasge('');
			setDisabledActivateButton(false);
		} else if (!value.length) {
			setLicenseValid('error');
			setErrorMesasge('Введите лицензионный ключ');
		} else {
			setLicenseValid('error');
			setErrorMesasge('Некорректный ключ');
		}
	};

	const toggleForceLicenseInputShow = () => {
		setForceLicenseInputShow(!forceLicenseInputShow);
	};

	const toggleLicenseInputShow = () => {
		setLicenseInputShow(!licenseInputShow);
	};

	React.useEffect(() => {
		if (opened !== 'license') {
			setLicenseInputShow(true);
		}
	}, [opened]);

	const activate = async () => {
		try {
			setIsLoading(true);
			if (licenseValid === 'success') {
				const res = await activateLicense({
					key: licenseValue,
				});
				if (res.status) {
					toggleLicenseInputShow();
					setCount(res.count);
				} else {
					setLicenseValid('error');
					setErrorMesasge(res.message);
				}
			} else if (licenseValue.length && !checkValidate(licenseValue)) {
				setErrorMesasge('Некорректный ключ');
			} else if (!licenseValue.length) {
				setLicenseValid('error');
				setErrorMesasge('Введите лицензионный ключ');
			}
			setDisabledActivateButton(true);
		} finally {
			setIsLoading(false);
		}
	};

	const showModal = () => {
		dispatch(
			setBitrixModal({
				show: true,
			})
		);

		setTimeout(() => {
			const scriptWrapper = document.createElement('script');
			scriptWrapper.setAttribute('data-b24-form','inline/34/44qi8k');
			scriptWrapper.setAttribute('data-skip-moving','true');
			const script = document.createElement('script'); 
			// eslint-disable-next-line no-useless-concat
			script.src = "https://crm.digtlab.ru/upload/crm/form/loader_34_44qi8k.js"+"?"+(Date.now()/180000|0);
			script.async = true;
			scriptWrapper.appendChild(script);
			const bitrix = document.getElementById('bitrix');
			bitrix?.appendChild(scriptWrapper)
		}, 100)
		
	};

	return (
		<Modal
			open={open}
			onClose={closeModal}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<div className="balance_card">
				<div className="balance_card__body">
					{((profile.data.balance === 0 && type === codeTypes.number) || (profile.data.balanceSms === 0 && type === codeTypes.sms)) && forceLicenseInputShow ? (
						<>
							<h2>Необходимо пополнить счет</h2>
							<div className={'balance_card__form'}>
								<p className="balance_card__form_count_info">
									Ваш баланс составляет <span>{type === codeTypes.number ? profile.data.balance : profile.data.balanceSms}</span>{' '}
									{type === codeTypes.number ? 'звонков' : 'смс'}.
								</p>
								<button
									className="balance_card__footer_link_activate"
									onClick={toggleForceLicenseInputShow}
								>
									<span>Введите лицензионный ключ</span>
								</button>
								<a href={MARKET_LINK_BUY} target="_blank" rel="noreferrer">
									<span className="balance_card__footer_link">
										Купите ключ в онлайн-магазине Kloud.one
									</span>
								</a>

								<div className="balance_card__form_options">
									<button className="balance_card__footer_link" />
									<CencelHeaderButton
										text={'Закрыть'}
										className="close_balance_success"
										onClick={closeModal}
									/>
								</div>
							</div>
						</>
					) : (
						<>
							<h2>Пополнение счета "Авторизация по телефону"</h2>
							<div className={'balance_card__form'}>
								{licenseInputShow ? (
									<>
										<span className="balance_card__form_label">
											Введите лицензионный ключ:
										</span>
										<CustomInput
											mode={licenseValid}
											placeholder="XXXX-XXXX-XXXX"
											value={licenseValue}
											changeInput={changeInput}
											errorMessage={errorMessage}
										/>
										<p className="balance_card__form_label_key">Нет ключа?</p>
										<a href={MARKET_LINK_BUY} target="_blank" rel="noreferrer">
											<span className="balance_card__footer_link">
												Купите в онлайн-магазине Kloud.one
											</span>
										</a>
										<span onClick={showModal}>
											<span className="balance_card__footer_link">
												Запросите триальную лицензию
											</span>
										</span>
										<div className="balance_card__form_buttons">
											<div className="balance_card__form_buttons_container">
												<CencelHeaderButton
													className="close_balance"
													disabled={isLoading}
													text={'Отмена'}
													onClick={closeModal}
												/>
												<CustomButtonHeader
													className="activate_balance"
													disabled={isLoading}
													text={'Активировать'}
													onClick={activate}
													disabledActivateButton={disabledActivateButton}
												/>
											</div>
										</div>
									</>
								) : (
									<div className={'balance_card__form'}>
										<p className="balance_card__form_success">
											Ключ активирован!
										</p>
										<p className="balance_card__form_count_info">
											На ваш баланс зачислено{' '}
											<span className="count">{count}</span> единиц.
										</p>
										<div className="balance_card__form_options">
											<button
												className="balance_card__footer_link"
												onClick={toggleLicenseInputShow}
											>
												<span className="balance_card__footer_link">
													Активировать ещё
												</span>
											</button>
											<CencelHeaderButton
												text={'Закрыть'}
												className="close_balance_success"
												onClick={closeModal}
											/>
										</div>
									</div>
								)}
							</div>
						</>
					)}
				</div>
				<CloseIcon className="close_button" onClick={closeModal} />
			</div>
		</Modal>
	);
};

export default BalanceModal;
