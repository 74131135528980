import React from 'react';
import { useDispatch } from 'react-redux';
import './style.css';
import CustomButtonWithPlus from '../buttons/customButtonWithPlus';
import ApplicationsCard from '../cards/applicationsCard';
import { setAddAppModal } from '../../redux/actions/modals';

const AppsPage = () => {
	const dispatch = useDispatch();

	const showModal = () => {
		dispatch(
			setAddAppModal({
				show: true,
			})
		);
	};

	return (
		<div className="apps_page">
			<h2>Добро пожаловать!</h2>
			<p>
				Авторизация по телефону - программное решение для идентификации пользователей по последним цифрам входящего номера телефона или SMS.
				Верификация пользователя выполняется по последним цифрам входящего звонка или SMS. Длина кодовой комбинации настраивается индивидуально.{' '}
			</p>
			<p>
				Для использования приложения Авторизация по телефону необходимо приобрести
				лицензионный ключ.
			</p>

			<div className="apps_page__applications_header">
				<h2>Приложения</h2>
				<CustomButtonWithPlus text="Добавить" onClick={showModal} />
			</div>
			<ApplicationsCard />
		</div>
	);
};

export default AppsPage;
