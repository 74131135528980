import React from 'react';
import './style.css';
import NavLogMenuButton from '../buttons/navLogMenuButton';

const NavLogMenu = (props) => {
	const { page, changePage } = props;
	return (
		<nav className="nav_log_menu">
			<NavLogMenuButton text="Журнал" page={page} onClick={changePage} />
			<NavLogMenuButton
				text="Активированные лицензии"
				page={page}
				onClick={changePage}
			/>
		</nav>
	);
};

export default NavLogMenu;
