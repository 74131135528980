import React from 'react';
import EditIconMuI from '@material-ui/icons/Edit';
import IconTooltip from '../../tooltips/iconTooltip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiSvgIcon-root:hover': {
			fill: '#495BBF',
		},
	},
}));

const EditIcon = (props) => {
	const { onClick } = props;
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<IconTooltip title="Редактировать">
				<EditIconMuI
					style={{ color: '#8387A0' }}
					fontSize="medium"
					onClick={onClick}
				/>
			</IconTooltip>
		</div>
	);
};

export default EditIcon;
