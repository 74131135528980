import { createSlice } from '@reduxjs/toolkit';

export const pageSlice = createSlice({
	name: 'page',
	initialState: {
		data: 'Приложения',
		table: 'Журнал',
	},
	reducers: {
		setDataPage: (state, data) => {
			state.data = data.payload;
		},
		setDataPageTable: (state, data) => {
			state.table = data.payload;
		},
	},
});

export const { setDataPage, setDataPageTable } = pageSlice.actions;

export default pageSlice.reducer;
