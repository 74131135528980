export const URL_SERVER = window._env_
	? window._env_.URL_API_SERVER
	: 'http://localhost:8085';
export const VERSION_RELEASE = '1.0.1';
export const AUTH_TYPE = window._env_ ? window._env_.AUTH_TYPE : 'trustedID';
export const LICENSE_POLICY =
	`${URL_SERVER}/docs/company-icense-agreement.pdf`;
export const PERSONAL_DATA_POLICY =
	`${URL_SERVER}/docs/company-policy-for-personal-data-processing.pdf`;
export const URL_SERVICES = window._env_
	? window._env_.URL_SERVICES
	: 'https://lkdev.etcd.kloud.one';
export const URL_SERVICE_CRM_TOOLS = window._env_
	? window._env_.URL_SERVICE_CRM_TOOLS
	: 'https://crmtools-dev.kloud.one';
export const MARKET_LINK_BUY = window._env_
	? window._env_.MARKET_LINK_BUY
	: 'https://kloud.one/id/#buy';
export const VERSION_TEXT = window._env_
	? window._env_.VERSION_TEXT
	: '1.0';
export const MAX_CODE_LENGTH = Number(window._env_
	? window._env_.MAX_CODE_LENGTH : 8);
export const codeTypes = {
	number:'number',
	sms:'sms',
}
export const translateCodes = {
	number:'Звонок',
	sms:'SMS',
}