import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import './style.css';

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiOutlinedInput-root': {
			height: 40,
			width: 310,
			borderRadius: 3,
			textAlign: 'left',
			fontFamily: 'Inter',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: '15px',
			lineHeight: '22px',
			// eslint-disable-next-line no-useless-computed-key
			['@media screen and (max-device-width: 330px)']: {
				width: '100%',
			},
		},
		'& .MuiOutlinedInput-input': {
			padding: '10px 34px 8px 12px',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			border: '1px solid #E0E2E7',
		},
		'& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			border: '1px solid #8387A0',
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			border: '1px solid #4D69FF',
			backgroundColor: 'none',
		},
		'& .MuiSelect-select:focus': {
			backgroundColor: 'inherit',
		},
	},
}));

const CustomSelect = (props) => {
	const { value, changeValue, list, valueField, nameField } = props;
	const classes = useStyles();
	return (
		<FormControl className={classes.root}>
			<Select
				variant="outlined"
				value={value}
				onChange={(e) => changeValue(e.target.value)}
				MenuProps={{
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'left',
					},
					transformOrigin: {
						vertical: 'top',
						horizontal: 'left',
					},
					getContentAnchorEl: null,
					style: {
						marginTop: 4,
					},
					elevation: 5,
				}}
			>
				<MenuItem value={'all'} key={0}>
					Все приложения
				</MenuItem>
				{list.map((item) => (
					<MenuItem value={item[valueField]} key={item.id}>
						{item[nameField]}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default CustomSelect;
