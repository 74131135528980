import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './style.css';
import NavMenuButton from '../buttons/navMenuButton';
import NavProfile from '../navProfile';
import { setDataPage } from '../../redux/actions/page';
import { getProfile } from '../../api/users';
import CustomSidebarMenu from '../customSidebarMenu';
import SidebarMenuButton from '../buttons/sidebarMenuButton';
import LinksServices from '../buttons/linksServices';
import { codeTypes, URL_SERVICES } from '../../utils/constants';
import Apps from '../icons/apps';
import Log from '../icons/log';
import AuthorizationIcon from '../icons/authorization';
import BalanceButton from '../buttons/balanceButton';
import { setBalanceModal } from '../../redux/actions/modals';
import ArrowBottomIcon from '../icons/arrowBottom';
import Wallet from '../icons/wallet';
import { logoutAuth } from '../../api/auth';

const Header = (props) => {
	const dispatch = useDispatch();
	const page = useSelector((state) => state.page);
	const { showApp } = props;
	const [openSidebarMenu, setOpenSidebarMenu] = React.useState(false);
	const profile = useSelector((state) => state.profile);

	const changePage = (text) => {
		if (text !== page.data) {
			dispatch(setDataPage(text));
			onCloseHandle();
		}
	};

	const showModal = (type) => {
		dispatch(
			setBalanceModal({
				show: true,
				type
			})
		);
	};

	const onOpenHandle = () => {
		setOpenSidebarMenu(true);
	};

	const onCloseHandle = () => {
		setOpenSidebarMenu(false);
	};

	const onClickMenuService = (serviceName) => {
		onCloseHandle();
		window.open(`${URL_SERVICES}/${serviceName}`);
	};

	const onLogout = () => {
		logoutAuth();
	}

	const MenuList = () => {
		return (
			<div className="header__sidebar_menu__list">
				<NavProfile />
				<SidebarMenuButton
					text="Приложения"
					page={page.data}
					onClick={changePage}
				/>
				<SidebarMenuButton
					text="Журнал"
					page={page.data}
					onClick={changePage}
				/>
				<div className="header__sidebar_menu__list_services">
					<SidebarMenuButton
						text="Прием показаний"
						page={page.data}
						onClick={() => onClickMenuService('counter')}
					/>
					<SidebarMenuButton
						text="Обзвон должников"
						page={page.data}
						onClick={() => onClickMenuService('obzvon')}
					/>
					<SidebarMenuButton
						text="Подтверждение заказа"
						page={page.data}
						onClick={() => onClickMenuService('shop')}
					/>
				</div>
				<div className="header__sidebar_menu__list_services">
					<SidebarMenuButton
						text="Выйти"
						page={page.data}
						onClick={onLogout}
					/>
				</div>
			</div>
		);
	};

	React.useEffect(() => {
		getProfile();
		showApp();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<header className="header">
			<div className="header__centralized_container">
				<div className="header__left_side">
					<CustomSidebarMenu
						open={openSidebarMenu}
						onOpenHandle={onOpenHandle}
						onCloseHandle={onCloseHandle}
						ChildComponent={MenuList}
					/>
					<div className="header__services">
						<LinksServices />
					</div>
					<div className="header__logo">
						<div onClick={onOpenHandle}>
							<AuthorizationIcon />
						</div>
						<span>авторизация по телефону</span>
						<p style={{whiteSpace:'nowrap'}}>|  Kloud.one</p>
					</div>
				</div>
				<div className="header__right_side">
					<NavProfile />
				</div>
			</div>
			<div className="navbar">
				<div className="navbar-header">
					<div className="navbar-header__container">
						<NavMenuButton
							text="Приложения"
							page={page.data}
							onClick={changePage}
							icon={<Apps />}
						/>

						<NavMenuButton
							text="Журнал"
							page={page.data}
							onClick={changePage}
							icon={<Log />}
						/>
					</div>
					<div className="navbar-header__balance">
						<BalanceButton
							balance={profile.data.balanceSms || 0}
							text="sms"
							onClick={() => showModal(codeTypes.sms)}
							icon={<Wallet />}
							arrow={<ArrowBottomIcon />}
						/>
						<BalanceButton
							balance={profile.data.balance || 0}
							text="звонков"
							onClick={() => showModal(codeTypes.number)}
							icon={<Wallet />}
							arrow={<ArrowBottomIcon />}
						/>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
