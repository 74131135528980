import React from 'react';
import { SwipeableDrawer } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import Footer from '../footer';
import './style.css';

const useStyles = makeStyles({
	drawer: {
		'& .MuiPaper-root': {
			width: 256,
		},
	},
});

const CustomSidebarMenu = (props) => {
	const { ChildComponent, open, onOpenHandle, onCloseHandle } = props;
	const classes = useStyles();

	return (
		<div className="sidebar_menu">
			<SwipeableDrawer
				anchor={'left'}
				open={open}
				onClose={onCloseHandle}
				onOpen={onOpenHandle}
				className={classes.drawer}
			>
				<div className="sidebar_menu__content">
					<ChildComponent />
				</div>
				<Footer />
			</SwipeableDrawer>
		</div>
	);
};

export default CustomSidebarMenu;
