import React from 'react';
import './style.css';

const BalanceButton = (props) => {
	const { onClick, text, arrow, icon, balance } = props;
	return (
		<button className="balance_button" onClick={onClick} {...props}>
			<div className="align">
				{icon}
				<span>{balance}</span>
				{text}
				<p>{arrow}</p>
			</div>
		</button>
	);
};

export default BalanceButton;
