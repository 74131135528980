import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { TextField, FormControl, InputAdornment } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiOutlinedInput-notchedOutline': {
			border: '1px solid #E0E2E7',
		},
		'& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			border: '1px solid #8387A0',
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			border: '1px solid #4D69FF',
		},
		'& .MuiInputBase-root': {
			fontFamily: 'Inter',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: '15px',
			lineHeight: '22px',
		},
		'& .MuiOutlinedInput-root': {
			height: 40,
			width: 310,
			textAlign: 'left',
			borderRadius: 3,
			// eslint-disable-next-line no-useless-computed-key
			['@media screen and (max-device-width: 330px)']: {
				width: '100%',
			},
		},
		'& .MuiSvgIcon-root': {
			fill: '#BEC3E6',
			'&:hover': {
				fill: '#8387A0',
				cursor: 'pointer',
			},
		},
		'& .Mui-focused .MuiSvgIcon-root': {
			fill: '#8387A0',
		},
	},
}));

const CustomSearch = (props) => {
	const { onSearch, value } = props;
	const classes = useStyles();

	const changeValue = (e) => {
		onSearch(e.target.value);
	};

	const clearSearch = () => {
		onSearch('');
	};

	return (
		<FormControl variant="outlined" className={classes.root}>
			<TextField
				variant="outlined"
				onChange={changeValue}
				placeholder="Поиск номера"
				value={value}
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon />
						</InputAdornment>
					),
					endAdornment: (
						<InputAdornment position="end">
							{value.length ? <CloseIcon onClick={clearSearch} /> : ''}
						</InputAdornment>
					),
				}}
			/>
		</FormControl>
	);
};

export default CustomSearch;
