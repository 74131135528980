import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconTooltip from '../../tooltips/iconTooltip';

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'& .MuiOutlinedInput-input': {
			border: '1px solid #E0E2E7',
			borderRadius: 3,
			boxSizing: 'border-box',
			padding: '9px 12px',
			height: 40,
			'&:hover': {
				border: '1px solid #90A0B7',
			},
			'&:focus': {
				border: '1px solid #4D69FF',
			},
		},
	},
	error: {
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'& .MuiOutlinedInput-input': {
			border: '1px solid #C91C00',
			borderRadius: 3,
			boxSizing: 'border-box',
			padding: '9px 12px',
			height: 40,
			'&:focus': {
				border: '2px solid #C91C00',
			},
		},
		'& .MuiFormHelperText-contained': {
			fontSize: '13px',
			lineHeight: '18px',
			color: '#C91C00',
			position: 'absolute',
			top: 45,
			margin: 0,
		},
	},
	success: {
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'& .MuiOutlinedInput-input': {
			border: '1px solid #4ABF73',
			borderRadius: 3,
			boxSizing: 'border-box',
			padding: '9px 12px',
			height: 40,
			'&:focus': {
				border: '2px solid #4ABF73',
			},
		},
	},
	disabled: {
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'& .MuiOutlinedInput-input': {
			backgroundColor: '#fcfcfc',
			border: '1px solid #E0E2E7',
			borderRadius: 3,
			boxSizing: 'border-box',
			padding: '9px 12px',
			height: 40,
		},
	},
	wrapper: {
		position: 'relative',
		width: '100%',
		'& .MuiSvgIcon-root': {
			fill: '#BEC3E6',
		},
	},
	icon: {
		position: 'absolute',
		top: 8,
		right: 8,
		'&:hover': {
			cursor: 'pointer',
			fill: '#495BBF',
		},
	},
	hide: {
		position: 'absolute',
		opacity: 0,
	},
}));

const CustomPassword = (props) => {
	const { placeholder, mode, value, changeInput, errorMessage } = props;
	const classes = useStyles();
	const [showPass, setShowPass] = React.useState(false);

	const changeValue = (e) => {
		changeInput(e.target.value);
	};

	const changeShowPass = () => {
		setShowPass(!showPass);
	};

	return (
		<div className={classes.wrapper}>
			<TextField
				className={classes[mode]}
				variant="outlined"
				autoComplete="off"
				value={value ? value : ''}
				placeholder={placeholder}
				onChange={changeValue}
				type={showPass ? 'text' : 'password'}
				helperText={mode === 'error' ? errorMessage : ''}
			/>
			<IconTooltip title="Показать">
				<VisibilityIcon
					className={!showPass ? classes.icon : classes.hide}
					onClick={changeShowPass}
				/>
			</IconTooltip>
			<IconTooltip title="Скрыть">
				<VisibilityOffIcon
					className={showPass ? classes.icon : classes.hide}
					onClick={changeShowPass}
				/>
			</IconTooltip>
		</div>
	);
};

export default CustomPassword;
