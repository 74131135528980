import React from 'react';
import './style.css';
import CommonInfoApp from '../commonInfoApp';
import CopyLinkInput from '../inputs/copyLinkInput';
import CustomInput from '../inputs/customInput';
import CencelButton from '../buttons/cencelButton';
import CustomButtonPhone from '../buttons/customButtonPhone';
import { useSelector, useDispatch } from 'react-redux';
import { setBalanceModal } from '../../redux/actions/modals';
import { URL_SERVER } from '../../utils/constants';

const InfoAuthOTP = (props) => {
	const { app } = props;
	const [phone, setPhone] = React.useState('+7 (');
	const [showInput, setShowInput] = React.useState(false);
	const [validPhone, setValidPhone] = React.useState('root');
	const profile = useSelector((state) => state.profile);
	const dispatch = useDispatch();
	const apiKey = btoa(`${app.client_id}:${app.client_secret}`);
	const addressRestCall = `${URL_SERVER}/api/v1/call?tel=${phone
		.replace(/\(/g, '')
		.replace(/ /g, '')
		.replace(/\)/g, '')
		.replace(/-/g, '')
		.replace(/\+/g, '')}&apiKey=${apiKey}`;

	const show = () => {
		setShowInput(true);
	};

	const hide = () => {
		setShowInput(false);
	};

	const execTest = async () => {
		if (profile?.data.balance === 0) {
			dispatch(setBalanceModal({ show: true }));
			return
		}

		const newMyWindow = (e) => {
			let h = 500,
				w = 600;
			// eslint-disable-next-line no-restricted-globals
			window.open(e, '', 'scrollbars=1,height='+Math.min(h, screen.availHeight)+',width='+Math.min(w, screen.availWidth)+',left='+Math.max(0, (screen.availWidth - w)/2)+',top='+Math.max(0, (screen.availHeight - h)/2));
			}
		let link = document.createElement('a');
		link.click(newMyWindow(addressRestCall));
	};

	const changePhoneValue = (value) => {
		let handlePhone = value
			.replace(/\(/g, '')
			.replace(/ /g, '')
			.replace(/\)/g, '')
			.replace(/-/g, '');
		if (handlePhone.length < 13 && /^[+]\d+$/.test(handlePhone)) {
			if (handlePhone.length && handlePhone.length < 3) {
				setPhone(`${handlePhone} `);
				setValidPhone('error');
			} else if (handlePhone.length && handlePhone.length === 3) {
				setPhone(`${handlePhone.slice(0, 2)} (${handlePhone.slice(2, 3)}`);
				setValidPhone('error');
			} else if (handlePhone.length && handlePhone.length < 6) {
				setPhone(`${handlePhone.slice(0, 2)} (${handlePhone.slice(2, 5)}`);
				setValidPhone('error');
			} else if (handlePhone.length && handlePhone.length === 6) {
				setPhone(
					`${handlePhone.slice(0, 2)} (${handlePhone.slice(
						2,
						5
					)}) ${handlePhone.slice(5, 6)}`
				);
				setValidPhone('error');			
			} else if (handlePhone.length && handlePhone.length < 9) {
				setPhone(
					`${handlePhone.slice(0, 2)} (${handlePhone.slice(
						2,
						5
					)}) ${handlePhone.slice(5, 8)}`
				);
				setValidPhone('error');
			} else if (handlePhone.length && handlePhone.length === 9) {
				setPhone(
					`${handlePhone.slice(0, 2)} (${handlePhone.slice(
						2,
						5
					)}) ${handlePhone.slice(5, 8)}-${handlePhone.slice(8, 9)}`
				);
				setValidPhone('error');
			} else if (handlePhone.length && handlePhone.length < 11) {
				setPhone(
					`${handlePhone.slice(0, 2)} (${handlePhone.slice(
						2,
						5
					)}) ${handlePhone.slice(5, 8)}-${handlePhone.slice(8, 10)}`
				);
				setValidPhone('error');
			} else if (handlePhone.length && handlePhone.length === 11) {
				setPhone(
					`${handlePhone.slice(0, 2)} (${handlePhone.slice(
						2,
						5
					)}) ${handlePhone.slice(5, 8)}-${handlePhone.slice(
						8,
						10
					)}-${handlePhone.slice(10, 12)}`
				);
				setValidPhone('error');
			} else if (handlePhone.length && handlePhone.length < 13) {
				setPhone(
					`${handlePhone.slice(0, 2)} (${handlePhone.slice(
						2,
						5
					)}) ${handlePhone.slice(5, 8)}-${handlePhone.slice(
						8,
						10
					)}-${handlePhone.slice(10, 12)}`
				);
				setValidPhone('success');
			} 
		} 
	};

	return (
		<div className="info_auth_otp">
			<span className="info_auth_otp__header">
				Авторизация One Time Password
			</span>
			<CommonInfoApp
				client_id={app.client_id}
				borderBottom={true}
				show={app.auth_type === 1 ? true : false}
			/>
			<div className="info_auth_otp__row">
				<span className="info_auth_otp__row__left_span margin_top">
					Авторизация One Time Password
				</span>
				<div className="info_auth_otp__row__right">
					<CopyLinkInput label="Ссылка" value={addressRestCall} />
				</div>
			</div>
			<div className="info_auth_otp__row__footer">
				<span className="info_auth_otp__row__left_span"></span>
				<div className="info_auth_otp__row__right">
					<span
						className={
							showInput
								? 'info_auth_otp__row__right_span hidden_block'
								: 'info_auth_otp__row__right_span'
						}
						onClick={show}
					>
						Протестировать
					</span>
					<div
						className={
							showInput
								? 'info_auth_otp__test'
								: 'info_auth_otp__test hidden_block'
						}
					>
						<div className="info_auth_otp__test__label">
							<span>
								Введите номер для создания запроса (баланс уменьшится на 1):
							</span>
						</div>
						<div className="info_auth_otp__test__input">
							<CustomInput
								mode={validPhone}
								value={phone}
								changeInput={changePhoneValue}
							/>
							<div className="info_auth_otp__test__input__buttons">
								<CustomButtonPhone text="Выполнить" disabledActivateButton={validPhone !== 'success'} onClick={execTest} />
								<CencelButton text={'Отмена'} onClick={hide} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default InfoAuthOTP;
