import React from 'react';
import Modal from '@mui/material/Modal';
import ReactDOM from 'react-dom';
import styles from './BitrixModal.module.css';

const BitrixModal = (props) => {
	const { open, setClose } = props;
		
	const closeModal = () => {
		setClose(false);
	};

	return ReactDOM.createPortal(
		<Modal
			open={open}
			onClose={closeModal}
		>
			<div id="bitrix" className={styles.new_script}>
			</div>
		</Modal>,
		document.getElementById('modal')
	);
};

export default BitrixModal;